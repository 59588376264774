
// register all of our base component, but this will also register all of our components that start with Base as a custom-element
import '../../components/core'
import '../../components/filters'
import {store, wait} from '../../store'
import Vue from 'vue'

import AttentionBlock from '../../components/AttentionBlock.vue'
import BaseOverlay from '../../components/overlays/BaseOverlay.vue'
import Hamburger from '../../components/BaseHamburger.vue';
import SideNav from '../../components/BaseSidenav.vue';
import HiddenPlanInput from '../../components/HiddenPlanInput.vue';

window.store = store;
window.components = {
    attentionBlock : new Vue({
        store,
        wait,
        render: h => h(AttentionBlock)
    }),
    baseOverlay: new Vue({
        store,
        wait,
        render: h => h(BaseOverlay)
    }),
    hamburger: new Vue({
        store,
        wait,
        render: h => h(Hamburger)
    }).$mount('#hamburger'),
    sidenav : new Vue({
        store, wait,
        render: h => h(SideNav)
    }).$mount('#sidenav'),
    planSerialized : new Vue({
        store,
        render: h => h(HiddenPlanInput)
    })
};