<template>
    <div class="attention-block">

        <h4 class="attention-block__title">
            Vergoeding van &euro; 121,- incl btw
        </h4>

        <p>
            Energiek Zeeland werkt met woningambassadeurs die in overleg met je jouw energetisch adviesplan definitief maken; welke maatregelen en in welke volgorde.
        </p>

        <BaseTooltipButton title="Waarom moet ik betalen?" class="white">
            <p>
                Stichting Energiek Zeeland heeft veel kosten gemaakt om de opleiding van de woningambassadeurs te ontwikkelen en de kennis voor energetisch advies in te bouwen in het ondersteunende web portal. Het merendeel van die kosten is gedekt door steun van overheid en bedrijfsleven. Wij vragen jou als burger ook een tegemoetkoming in deze kosten. In vergelijking met andere adviezen is dit bedrag voor jou zeker een scherp aanbod.
            </p>
        </BaseTooltipButton>

        <BaseTooltipButton title="Wat doet de woningambassadeur precies?" class="white">
            <p>
                De woningambassadeur zal contact met je opnemen om samen een goed moment te vinden voor het bezoek aan jouw woning. Tijdens dat bezoek (1,5-2 uur) worden jouw persoonlijke wensen genoteerd en wordt de situatie in jouw woning (glas, muren, daken en andere zaken) opgemeten en genoteerd in het web portal van Energiek Zeeland. De berekening levert je vervolgens een persoonlijk energetisch adviesplan op met realistisch kostenplaatje dat je thuis kunt opslaan, raadplegen en gebruiken voor jouw persoonlijke plannen.
            </p>
        </BaseTooltipButton>

  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
